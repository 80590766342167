import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';

import Slider from 'react-slick';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';

import lawFirm from '../../assets/images/02 fibonacci--law-firm.svg';
import inHouse from '../../assets/images/01 fibonacci--corporate-legal.svg';
import noLpm from '../../assets/images/03 fibonacci--we-dont-have-lpm.svg';
import withLpm from '../../assets/images/04 fibonacci--we-have-lpm.svg';
import loop4 from '../../assets/videos/FIBONACCI_SCENE3_SHOT4_LOOP.mp4';

// eslint-disable-next-line import/extensions
import 'swiped-events/dist/swiped-events.min.js';

import '../../assets/stylesheets/application.scss';
import helgaButcher from '../../assets/images/helga-butcher.jpg';
import ventures from '../../assets/images/venturesLogo.svg';
import TestimonialSlider from '../../components/TestimonialSlider';
import Quote from '../../components/Quote';

const LegalProjectManagement = ({ path }) => {
  const slider = useRef(null);

  const [organisationType, setOrganisationType] = useState(null);
  const [useLpm, setUseLpm] = useState(null);
  const [page, setPage] = useState(0);
  /* eslint-disable no-unused-vars */
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  useEffect(() => {
    const handler = (event) => {
      if (videoPlayerOpen) return;

      event.preventDefault();
      if (event.deltaY > 0) {
        if (page === 2 && organisationType == null) return;
        if (page === 3 && useLpm == null) return;
        slider.current.slickNext();
      } else {
        slider.current.slickPrev();
      }
    };

    const swipeUpListener = () => {
      if (videoPlayerOpen) return;
      if (page === 2 && organisationType == null) return;
      if (page === 3 && useLpm == null) return;
      slider.current.slickNext();
    };

    const swipeDownListener = () => {
      if (videoPlayerOpen) return;
      slider.current.slickPrev();
    };

    window.addEventListener('wheel', handler, { passive: false });
    window.addEventListener('swiped-up', swipeUpListener);
    window.addEventListener('swiped-down', swipeDownListener);

    const resizeHandler = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('wheel', handler);
      window.removeEventListener('resize', resizeHandler);
      window.removeEventListener('swiped-up', swipeUpListener);
      window.removeEventListener('swiped-down', swipeDownListener);
    };
  }, [page, videoPlayerOpen, useLpm]);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-lpm' }}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <title>Fibonacci - Work beautifully</title>
        <meta name="title" content="Keep work moving, without the chaos" />
        <meta name="description" content="A better way to work, together." />
        <meta name="image" content="/metaimages/homepage.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fibonacci.legal/" />
        <meta property="og:title" content="Keep work moving, without the chaos" />
        <meta property="og:description" content="A better way to work, together." />
        <meta property="og:image" content="https://www.fibonacci.legal/metaimages/homepage.jpg" />
      </Helmet>
      <div className="content-wrapper -home">
        <Header path={path} />
        <main>
          <div className="content">
            <div className="slider-container">
              <Slider
                infinite={false}
                speed={300}
                slidesToShow={1}
                slidesToScroll={1}
                vertical
                afterChange={(index) => {
                  setPage(index);
                }}
                ref={slider}
              >
                <div className="fib-slide home-slide">
                  <div className="banner -why">
                    <div className="chip">LPM</div>
                    <h1>
                      Legal project
                      <br />
                      management, delivered.
                    </h1>
                    <p>
                      A better way to deliver legal services
                    </p>
                  </div>
                  <div className="flexible-container -loop">
                    <video style={{ height: '35vh' }} className="video" autoPlay loop muted playsInline>
                      <source src={loop4} type="video/mp4" />
                      <source src={loop4} type="video/ogg" />
                    </video>
                  </div>
                </div>
                <div className="fib-slide home-slide2">
                  <div className="testimonial-container">
                    <TestimonialSlider>
                      <Quote
                        classes="quote-responsive"
                        author="Helga Butcher"
                        position=" Director, Legal Project Management"
                        avatar={helgaButcher}
                        logo={ventures}
                        content="LPM frees up lawyers to focus on high value and strategic legal work.
                                  We've seen LPMs save at least 20% of senior lawyer time on a matter.
                                  These time savings and efficiencies helps both in-house and law firms"
                      />
                    </TestimonialSlider>
                    <div className="banner">
                      <Link className="base-button -bold -medium" to="/blog/from-the-legal-project-managers-mouth/">Read about Helga’s LPM journey at Barclays</Link>
                    </div>
                  </div>
                </div>
                <div className="fib-slide home-slide3">
                  <div className="survey-page">
                    <div className="content">
                      <h2 className="title text-center mx-auto">Select your department</h2>
                      <div className="options">
                        <button
                          className={`item ${organisationType === 'Corporate' && '-selected'}`}
                          onClick={() => {
                            setOrganisationType('Corporate');
                            slider.current.slickNext();
                          }}
                        >
                          <div className="label">Corporate legal</div>
                          <img src={inHouse} alt="" />
                        </button>
                        <button
                          className={`item ${organisationType === 'Law Firm' && '-selected'}`}
                          onClick={() => {
                            setOrganisationType('Law Firm');
                            slider.current.slickNext();
                          }}
                        >
                          <div className="label">Law firm</div>
                          <img src={lawFirm} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fib-slide home-slide4">
                  <div className="survey-page">
                    <div className="content">
                      <h2 className="title text-center mx-auto">
                        Does your organisation use
                        <br />
                        Legal Project Management (LPM)?
                      </h2>
                      <div className="options">
                        <button
                          className={`item ${useLpm === false && '-selected'}`}
                          onClick={() => {
                            setUseLpm(false);
                            slider.current.slickNext();
                          }}
                        >
                          <div className="label">We don&apos;t have LPM</div>
                          <img src={noLpm} alt="" />
                        </button>
                        <button
                          className={`item ${useLpm === true && '-selected'}`}
                          onClick={() => {
                            setUseLpm(true);
                            slider.current.slickNext();
                          }}
                        >
                          <div className="label">We have LPM</div>
                          <img src={withLpm} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fib-slide">
                  <div className={`survey-page -center ${useLpm ? '-section-1' : '-section-2'}`}>
                    <div className="content -full">
                      {useLpm ? (
                        <div className="prompt">
                          <h1 className="title">Congratulations</h1>
                          <p className="message">
                            You understand that LPM can free up lawyers to focus on what
                            they do best, that is the substantive legal issues at hand.
                          </p>
                          <p className="message">
                            But you know you can do more. Despite using technology, you’re bogged
                            down inputting data in multiple locations and spending hours extracting
                            this data for information. Inefficiency is not in your DNA and we
                            understand the frustration.
                          </p>
                          <p className="message">
                            This is why we built Fibonacci - to provide a single source of
                            truth in the one platform - that can expose further efficiencies
                            and realise the productivity gains offered by LPM.
                          </p>
                          <div className="links">
                            <Link className="link -left" to="/features">Explore Fibonacci</Link>
                          </div>
                        </div>
                      ) : (
                        <div className="prompt">
                          <h1 className="title">No stress, you’re not alone.</h1>
                          <p className="message">
                            You’ve made the first step to learn about the efficiency and
                            productivity gains that can be introduced by LPM. Whether you’re in
                            the process of creating an LPM department, or want to engrain LPM
                            practices in your organisation&apos;s legal services delivery framework,
                            Fibonacci can help.
                          </p>
                          <div className="links">
                            <Link className="link -left" to="/features">Explore Fibonacci</Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Slider>
              <div className="page-controller">
                {
                  (page === 0 || page === 1) && (
                    <div className="indicator">Scroll down</div>
                  )
                }
                {
                  (page > 1 && page <= 3) && (
                    <div className="indicator">
                      {page - 1}
                      {' '}
                      out of 2 questions
                    </div>
                  )
                }
                {
                  page > 2 && (
                    <button
                      className="repeat"
                      onClick={() => {
                        setOrganisationType(null);
                        setUseLpm(null);
                        slider.current.slickGoTo(2);
                      }}
                    >
                      Repeat questionnaire
                    </button>
                  )
                }
                <div className="buttons">
                  {
                    page > 0 && (
                      <>
                        <button
                          className="button"
                          onClick={() => {
                            slider.current.slickPrev();
                          }}
                        >
                          <i className="material-icons icon">expand_less</i>
                        </button>
                        <div className="divider" />
                      </>
                    )
                  }
                  {
                    page < 3 && (
                      <button
                        className="button"
                        onClick={() => {
                          if (page === 2 && organisationType == null) return;
                          if (page === 3 && useLpm == null) return;
                          slider.current.slickNext();
                        }}
                      >
                        <i className="material-icons icon">expand_more</i>
                      </button>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default LegalProjectManagement;
